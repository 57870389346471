import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P2 = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p2.webp" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">道口反向报警器（型号：DKBJ-V-K）</span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <ul>
                        <li>本设备技术成熟、先进、性能可靠</li>
                        <li>结构设计合理，易操作，外壳坚固耐用，美观大方；</li>
                        <li>预置后备电源，保证设备正常工作；；</li>
                        <li>列车接近道口时报警，提示过往行人和车辆注意安全；</li>
                        <li>声、光、语音报警提示；</li>
                        <li>可靠的设备自检功能；</li>
                        
                        </ul>
                        <Alert color="primary">
                        产品简介
                    </Alert>
                    <div class="detail_specific_desc detail_item_content show_detail_content">
                        
                        <p>&nbsp; &nbsp; “无人道口报警装置”是专用于有、无交流电源供电的铁路道口安全防护报警的专利产品。本产品可以做有、无线道口报警装置。当列车接近道口时发出声、光、语音报警信号，以提示过往行人和车辆，保证人、车安全。本装置不干扰铁路上的设施设备，是道口报警的理想产品</p>
                        <p style="line-height:1.5em;"><br/></p></div>

                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P2





